import { useState } from 'react'
import mail from './mail.svg';
import Logo from './Logo'

const Underdev = () => {

    const [showPopup, setPopup] = useState(false)
    const togglePopup = (e) => {
        setPopup(!showPopup)
    }
    return (
        <header className="App-header">
            <p className="App-soon">
                bald <br />
                verfügbar
            </p>
            <div className="App-logo"><Logo /></div>
            <p>weil wir lieben was wir tun!</p>
            <p className={showPopup ? 'App-mail-active':"App-contact"} onClick={togglePopup} >
                KONTAKT

            </p>
            
            {showPopup ? <div className="App-mail"><img src={mail} alt="mail" /></div> : null }
            
        </header>
    )
}

export default Underdev
